import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';

type Contract = {
  allGetContractYaml: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
};

const query = graphql`
  query {
    allGetContractYaml {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`;

const GetContract: React.FC = () => {
  const data = useStaticQuery<Contract>(query);
  return (
    <Layout>
      <Container className="general">
        <PageTitle title={data.allGetContractYaml.edges[0].node.title} />
        <div dangerouslySetInnerHTML={{__html: data.allGetContractYaml.edges[0].node.content}}></div>
      </Container>
    </Layout>
  );
};

export default GetContract;
